import { Helmet } from "react-helmet";

import { Container } from "../components/utils";
import NavLegal from "../layouts/NavLegal";

import Footer from "./Footer";

const Legal = () => {
    return (
        <div>
            <Helmet>
                <title>Treko Legal - Casa de Cambio</title>
                <meta
                    name="description"
                    content="Treko pagina web de casa de cambio de criptomonedas. Terminos y condiciones, Legal"
                />
                <link rel="canonical" href="https://treko.pe/" />
            </Helmet>

            <NavLegal />
            <section
                className="w-full overflow-hidden bg-[#f2f3f9] wow fadeInUp"
                style={{ backgroundImage: "url('/images/trekobg.svg')", backgroundSize: "contain" }}
            >
                <div className="relative bg-auto bg-no-repeat bg-[top_120px_right] md:bg-[top_70px_right] lg:bg-[top_100px_right]">
                    <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
                        <Container className="wow fadeInUp relative -mt-[110px]">
                            <div
                                id="kyc"
                                className="wow fadeInUp text-primary space-y-8 mt-28 mb-16 px-9 py-9"
                            >
                                <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                                    POLÍTICA DE PREVENCIÓN DE DELITOS
                                </h4>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    INTRODUCCIÓN
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    La política de Prevención de Delitos de TREKO se estructura en
                                    dos etapas clave: "Conoce a tu Cliente" (KYC, por sus siglas en
                                    inglés, Know Your Customer) y "Prevención de Blanqueo de
                                    Capitales" (AML, por sus siglas en inglés, Anti-Money
                                    Laundering). Estas etapas están diseñadas para evitar que
                                    nuestros servicios sean utilizados en actividades ilícitas.
                                    Contamos con procedimientos específicos cuya implementación y
                                    cumplimiento son responsabilidad del área de Operaciones,
                                    mientras que la fiscalización de su cumplimiento recae en el
                                    área Legal.
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    OBJETIVOS Y ALCANCE
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    En TREKO, nos comprometemos a proteger el sistema financiero
                                    peruano y cumplir con las normativas aplicables, al tiempo que
                                    respetamos la seguridad de los datos y el sistema financiero de
                                    nuestros clientes según lo establece la ley. Para ello, hemos
                                    estructurado procedimientos que nos permiten:
                                    <ul className="text-sm font-medium space-y-2">
                                        <li>
                                            - Identificar de manera efectiva el origen de los fondos
                                            de nuestros clientes.
                                        </li>
                                        <li>
                                            - Detectar y abordar actividades sospechosas mediante la
                                            vigilancia constante de transacciones y la
                                            identificación de patrones inusuales.
                                        </li>
                                        <li>
                                            - Construir una relación segura con nuestros clientes,
                                            garantizando la protección de sus datos y operaciones.
                                        </li>
                                        <li>- Cumplir con la normativa legal vigente.</li>
                                        <li>
                                            - Evaluar y gestionar los riesgos asociados con
                                            transacciones de activos digitales, clientes y
                                            contrapartes.
                                        </li>
                                        <li>
                                            - Colaborar proactivamente con las autoridades y
                                            organismos reguladores para garantizar la transparencia
                                            y la integridad del mercado.
                                        </li>
                                        <li>
                                            - Recopilar información completa y precisa sobre
                                            nuestros clientes mediante un régimen reforzado de
                                            debida diligencia en el conocimiento del cliente.
                                        </li>
                                    </ul>
                                </p>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Estos procedimientos son aplicables a través de todos nuestros
                                    canales de comunicación en todo el territorio peruano.
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    MARCO LEGAL, NORMATIVO Y REGULATORIO
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    El marco legal nacional en el que se basan nuestras políticas de
                                    prevención del delito incluye las siguientes normativas:
                                    <ul className="text-sm font-medium space-y-2">
                                        <li>
                                            - Ley N° 27651: Ley del Sistema Privado de
                                            Administración de Fondos de Pensiones.
                                        </li>
                                        <li>
                                            - Ley N° 27693: Ley del Sistema Nacional de Control y
                                            Contraloría General de la República.
                                        </li>
                                        <li>
                                            - Ley N° 29701: Ley de Protección de Datos Personales.
                                        </li>
                                        <li>
                                            - Ley N° 30309: Promoción de la investigación
                                            científica, desarrollo tecnológico e innovación
                                            tecnológica.
                                        </li>
                                        <li>
                                            - Ley N° 30424: Actualización de la Ley del Sistema
                                            Nacional de Control y de la Contraloría General de la
                                            República.
                                        </li>
                                        <li>
                                            - Ley N° 30737: Responsabilidad administrativa de las
                                            personas jurídicas por el delito de cohecho activo
                                            transnacional.
                                        </li>
                                    </ul>
                                    Resoluciones de la SBS:
                                    <ul className="text-sm font-medium space-y-2">
                                        <li>
                                            - Resolución SBS N° 1630-2009: Reglamento para la
                                            Prevención del Lavado de Activos y del Financiamiento
                                            del Terrorismo en las Empresas del Sistema Financiero.
                                        </li>
                                        <li>
                                            - Resolución SBS N° 1884-2010: Reglamento de Control
                                            Interno para la Prevención del Lavado de Activos y del
                                            Financiamiento del Terrorismo.
                                        </li>
                                        <li>
                                            - Resolución SBS N° 2660-2015: Reglamento de Gestión de
                                            Riesgos de Lavado de Activos y del Financiamiento del
                                            Terrorismo.
                                        </li>
                                    </ul>
                                    En el ámbito internacional, se consideran las siguientes
                                    normativas:
                                    <ul className="text-sm font-medium space-y-2">
                                        <li>
                                            - Convención de las Naciones Unidas contra la
                                            Corrupción.
                                        </li>
                                        <li>- Grupo de Acción Financiera (GAFI).</li>
                                        <li>- Estándares del GAFI para criptomonedas.</li>
                                        <li>
                                            - Oficina de las Naciones Unidas contra la Droga y el
                                            Delito (UNODC)
                                        </li>
                                    </ul>
                                    Estas normativas aseguran que nuestras políticas de prevención
                                    del delito no solo cumplan con los requisitos nacionales, sino
                                    que también se alineen con los estándares internacionales más
                                    rigurosos.
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    PRINCIPIOS FUNDAMENTALES
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    En TREKO, nuestra política de prevención del delito se basa en
                                    principios fundamentales que guían nuestras acciones y
                                    decisiones. Estos principios son esenciales para garantizar el
                                    cumplimiento de las normativas legales nacionales e
                                    internacionales, así como para fomentar una cultura de
                                    integridad y transparencia en todas nuestras operaciones.
                                    <ul className="text-sm font-medium space-y-2">
                                        <li>Cumplimiento Legal y Normativo:</li>
                                        <ul className="text-sm font-medium space-y-2">
                                            <li>
                                                - Nos comprometemos a cumplir con todas las leyes y
                                                regulaciones aplicables a nivel nacional e
                                                internacional. Esto incluye, pero no se limita a, la
                                                Ley del Sistema Nacional de Control y de la
                                                Contraloría General de la República, la Ley de
                                                Protección de Datos Personales, y las normativas
                                                emitidas por la Superintendencia de Banca, Seguros y
                                                Administradoras de Fondos de Pensiones (SBS).
                                            </li>
                                            <li>
                                                - Adoptamos las recomendaciones y estándares
                                                internacionales establecidos por organismos como el
                                                Grupo de Acción Financiera (GAFI) y la Oficina de
                                                las Naciones Unidas contra la Droga y el Delito
                                                (UNODC).
                                            </li>
                                        </ul>
                                        <li>Mejora Continua:</li>
                                        <ul>
                                            <li>
                                                - En TREKO, creemos en la importancia de la mejora
                                                continua de nuestras políticas y prácticas.
                                                Regularmente revisamos y actualizamos nuestras
                                                políticas de prevención del delito para adaptarnos a
                                                las nuevas amenazas y cambios en el entorno
                                                regulatorio.
                                            </li>
                                            <li>
                                                - Fomentamos una cultura de aprendizaje y
                                                adaptación, capacitando constantemente a nuestro
                                                equipo para que esté al tanto de las mejores
                                                prácticas y desarrollos en la prevención del lavado
                                                de dinero y otros delitos financieros.
                                            </li>
                                        </ul>
                                        <li>Transparencia y Responsabilidad:</li>
                                        <ul>
                                            <li>
                                                - La transparencia es un pilar fundamental de
                                                nuestra política. Nos aseguramos de que nuestros
                                                clientes y socios comprendan nuestras prácticas y
                                                políticas, promoviendo una comunicación abierta y
                                                honesta.
                                            </li>
                                            <li>
                                                - Mantenemos registros detallados y auditables de
                                                todas nuestras operaciones para facilitar la
                                                supervisión y el cumplimiento de nuestras
                                                obligaciones legales.
                                            </li>
                                        </ul>
                                        <li>Colaboración con las Autoridades:</li>
                                        <ul>
                                            <li>
                                                - Colaboramos activamente con las autoridades
                                                nacionales e internacionales para prevenir y
                                                combatir el delito. Esto incluye el intercambio de
                                                información y la cooperación en investigaciones
                                                cuando sea necesario.
                                            </li>
                                            <li>
                                                - Nos comprometemos a reportar actividades
                                                sospechosas y a trabajar junto a las autoridades
                                                para garantizar la integridad del sistema
                                                financiero.
                                            </li>
                                        </ul>
                                        <li>Protección de Datos y Privacidad:</li>
                                        <ul>
                                            <li>
                                                - Respetamos y protegemos la privacidad de nuestros
                                                clientes, asegurando que la información personal y
                                                financiera se maneje de acuerdo con las leyes de
                                                protección de datos.
                                            </li>
                                            <li>
                                                - Implementamos medidas de seguridad robustas para
                                                proteger los datos contra accesos no autorizados y
                                                otras amenazas.
                                            </li>
                                        </ul>
                                        <li>Compromiso con la Comunidad:</li>
                                        <ul>
                                            <li>
                                                - Nos esforzamos por contribuir al bienestar de la
                                                comunidad peruana mediante la promoción de prácticas
                                                financieras responsables y la inclusión financiera.
                                            </li>
                                            <li>
                                                - Educamos a nuestros clientes sobre el uso seguro y
                                                efectivo de las criptomonedas, empoderándolos para
                                                tomar decisiones informadas y responsables.
                                            </li>
                                        </ul>
                                    </ul>
                                    Estos principios fundamentales son la base sobre la cual
                                    construimos nuestras políticas y operamos día a día, reafirmando
                                    nuestro compromiso con la integridad, la transparencia y la
                                    responsabilidad en todas nuestras actividades.
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    PROCEDIMIENTOS KYC (Conoce a tu Cliente)
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Identificación del Cliente:
                                    <br />
                                    Recolección de datos personales y documentación de
                                    identificación válida.
                                    <br />
                                    Verificación de la autenticidad de los documentos y la identidad
                                    del cliente a través de fuentes confiables.
                                    <br />
                                    2. Perfil del Cliente:
                                    <br />
                                    Evaluación del perfil financiero del cliente, incluyendo la
                                    fuente de fondos y el propósito de las transacciones.
                                    <br />
                                    Monitoreo continuo para identificar cambios en el comportamiento
                                    del cliente que puedan indicar riesgos.
                                    <br />
                                    Implementación de un sistema de evaluación de riesgos basado en
                                    el perfil laboral y el monto de las operaciones:
                                    <br />
                                    Riesgo Bajo: Clientes que realizan operaciones continuas de
                                    hasta mil soles.
                                    <br />
                                    Riesgo Moderado: Clientes que realizan operaciones entre mil y
                                    cinco mil soles.
                                    <br />
                                    Riesgo Alto: Clientes que realizan operaciones entre cinco mil y
                                    diez mil soles.
                                    <br />
                                    Los clientes que son Personas Políticamente Expuestas (PEP) se
                                    consideran de riesgo alto, independientemente del monto de las
                                    operaciones.
                                    <br />
                                    3. Gestión de Personas Políticamente Expuestas (PEP):
                                    <br />
                                    Implementación de procedimientos para la identificación de PEP
                                    durante el proceso de KYC.
                                    <br />
                                    Monitoreo continuo de la relación con clientes identificados
                                    como PEP.
                                    <br />
                                    Evaluación y gestión de riesgos adicionales asociados con
                                    clientes PEP, incluyendo la revisión regular de sus perfiles y
                                    transacciones.
                                    <br />
                                    4. Registro y Almacenamiento de Datos:
                                    <br />
                                    Mantenimiento de registros precisos y actualizados de la
                                    información del cliente.
                                    <br />
                                    Protección de los datos contra accesos no autorizados y su
                                    almacenamiento conforme a las leyes de protección de datos.
                                    <br />
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    PROCEDIMIENTOS KYC (Conoce a tu Cliente)
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    El procedimiento AML de TREKO se activará bajo las siguientes
                                    circunstancias:
                                    <br />
                                    Cuando las operaciones acumuladas de un cliente en un mes
                                    calendario sumen más de 50,000 soles.
                                    <br />
                                    Cuando una sola operación realizada por un cliente involucre un
                                    movimiento igual o mayor a 10,000 soles.
                                    <br />
                                    Además no aceptamos pagos con tarjeta de crédito ni operaciones
                                    superiores a 50,000 soles, y requerimos transferencias bancarias
                                    para montos superiores a 700 soles. Aceptamos depósitos en
                                    efectivo por cajero o agente, solo para montos menores a 700
                                    soles. <br />
                                    Estas condiciones activan el procedimiento AML con el objetivo
                                    de asegurar el cumplimiento de las regulaciones vigentes contra
                                    el lavado de dinero y la financiación del terrorismo. Durante la
                                    activación de este procedimiento, se aplicarán medidas
                                    adicionales de debida diligencia y monitoreo para mitigar
                                    riesgos y mantener la integridad de nuestras operaciones.
                                    <br />
                                    Identificación del origen de los fondos:
                                    <br />
                                    Recolección de datos del origen del dinero y declaración por
                                    parte del cliente sobre esos fondos. <br />
                                    Verificación de la autenticidad de los documentos y la identidad
                                    del cliente a través de fuentes confiables.
                                    <br />
                                    Monitoreo de Transacciones:
                                    <br />
                                    Implementación de sistemas para monitorear y analizar
                                    transacciones en tiempo real.
                                    <br />
                                    Identificación de patrones inusuales y actividades sospechosas.
                                    <br />
                                    Reporte de Actividades Sospechosas:
                                    <br />
                                    Establecimiento de procedimientos para reportar actividades
                                    sospechosas a las autoridades competentes.
                                    <br />
                                    Capacitación del personal para identificar y reportar
                                    actividades sospechosas.
                                    <br />
                                    Debida Diligencia Reforzada:
                                    <br />
                                    Aplicación de medidas adicionales de verificación en casos de
                                    alto riesgo.
                                    <br />
                                    Evaluación continua de los riesgos asociados a clientes,
                                    transacciones y contrapartes.
                                    <br />
                                    Colaboración con Entidades Regulatorias:
                                    <br />
                                    Cooperación con las autoridades y el cumplimiento de las
                                    solicitudes de información en el marco de investigaciones.
                                    <br />
                                    Participación en iniciativas y programas de prevención del
                                    blanqueo de capitales a nivel nacional e internacional.
                                    <br />
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    SANCIONES POR INCUMPLIMIENTO O INFORMACIÓN FALSA
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    En TREKO, estamos comprometidos con el cumplimiento estricto de
                                    los procedimientos de Conoce a tu Cliente (KYC) y Contra el
                                    Lavado de Dinero (AML) para garantizar la integridad de nuestras
                                    operaciones y el cumplimiento de las regulaciones vigentes.
                                    Cualquier cliente que incumpla deliberadamente con estos
                                    procedimientos o proporcione información falsa estará sujeto a
                                    las siguientes sanciones:
                                    <br />
                                    Advertencia Escrita: En el caso de un primer incumplimiento o
                                    provisión de información falsa, el cliente recibirá una
                                    advertencia formal por escrito. Esta advertencia detallará la
                                    naturaleza del incumplimiento y las implicaciones adicionales de
                                    futuras violaciones.
                                    <br />
                                    Suspensión Temporal de Servicios: Para un segundo incumplimiento
                                    o caso de información falsa, TREKO se reserva el derecho de
                                    suspender temporalmente los servicios del cliente afectado. Esta
                                    suspensión será notificada por escrito y estará sujeta a
                                    revisión según la gravedad del incumplimiento.
                                    <br />
                                    Multas Monetarias: En casos graves o reincidentes de
                                    incumplimiento o falsificación de información, se aplicarán
                                    multas monetarias proporcionales a la gravedad del caso y al
                                    riesgo asociado para TREKO y sus clientes.
                                    <br />
                                    Terminación del Servicio: En situaciones de fraude grave o
                                    incumplimientos repetidos, TREKO podrá rescindir el servicio del
                                    cliente involucrado de acuerdo con las disposiciones
                                    contractuales y las leyes aplicables.
                                    <br />
                                    Sanciones para Funcionarios de TREKO: Cualquier funcionario de
                                    TREKO que viole deliberadamente los procedimientos KYC/AML
                                    estará sujeto a medidas disciplinarias severas, incluyendo el
                                    despido inmediato. Además, se realizará un reporte a las
                                    autoridades competentes según las regulaciones vigentes.
                                    <br />
                                    Reporte a Autoridades Competentes: Si se descubre actividad
                                    ilegal o sospechosa significativa durante los procedimientos
                                    KYC/AML, TREKO cooperará plenamente con las autoridades
                                    competentes y cumplirá con las obligaciones de reporte según las
                                    regulaciones vigentes.
                                    <br />
                                    Revisión de Procedimientos: Después de cualquier incidente de
                                    incumplimiento o información falsa, TREKO revisará y actualizará
                                    sus procedimientos KYC/AML para fortalecer las medidas de
                                    prevención y mejorar la detección temprana de irregularidades.
                                    <br />
                                    Estas sanciones se aplicarán de manera justa y consistente,
                                    asegurando que todos los clientes comprendan la importancia del
                                    cumplimiento de los procedimientos KYC/AML para mantener la
                                    integridad y seguridad de nuestras operaciones y del sistema
                                    financiero en general.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    CONCLUSIÓN
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    La política de prevención del delito de TREKO está diseñada para
                                    asegurar el cumplimiento legal, fomentar la transparencia y
                                    responsabilidad, y proteger a nuestros clientes y al sistema
                                    financiero. Mediante la implementación de procedimientos
                                    rigurosos y la colaboración activa con las autoridades, nos
                                    comprometemos a mantener la integridad de nuestras operaciones y
                                    a contribuir a la lucha contra el delito financiero.
                                </p>
                            </div>
                        </Container>
                        <Container className="wow fadeInUp relative -mt-[110px]">
                            <div
                                id="terms"
                                className="wow fadeInUp text-primary space-y-8 mt-28 mb-16 px-9 py-9"
                            >
                                <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                                    TÉRMINOS Y CONDICIONES
                                </h4>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Bienvenido a TREKO, donde nos comprometemos a facilitar la
                                    compra y venta de criptomonedas de manera segura y transparente.
                                    Al utilizar nuestros servicios, usted acepta los siguientes
                                    términos y condiciones:
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    MARCO LEGAL Y NORMATIVO
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Normativas Nacionales:
                                    <br />
                                    TREKO opera en estricto cumplimiento con la legislación peruana,
                                    incluyendo la Ley N° 27651, Ley N° 27693, Ley N° 29701, Ley N°
                                    30309, Ley N° 30424, Ley N° 30737, y las resoluciones emitidas
                                    por la Superintendencia de Banca, Seguros y AFP (SBS).
                                    <br />
                                    2. Normativas Internacionales:
                                    <br />
                                    Nos adherimos a estándares internacionales como la Convención de
                                    las Naciones Unidas contra la Corrupción, las directrices del
                                    Grupo de Acción Financiera Internacional (GAFI) para
                                    criptomonedas, y las recomendaciones de la Oficina de las
                                    Naciones Unidas contra la Droga y el Delito (UNODC).
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    POLÍTICA DE PREVENCIÓN DEL DELITO
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Etapas Clave:
                                    <br />
                                    Implementamos procesos de "Conoce a tu Cliente" (KYC) y
                                    "Prevención de Blanqueo de Capitales" (AML) detalladas en
                                    nuestra Política de Prevención del Delito.
                                    <br />
                                    2. Procedimientos Específicos:
                                    <br />
                                    Activamos el procedimiento AML si un cliente acumula operaciones
                                    por más de 50,000 soles en un mes o realiza una operación
                                    individual superior a 10,000 soles.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    POLÍTICA DE COMPRA Y VENTA
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Procedimientos Operativos:
                                    <br />
                                    Las transacciones están sujetas a un procedimiento KYC
                                    obligatorio para la identificación del cliente. Para operaciones
                                    menores a 10,000 soles, no es necesario activar el procedimiento
                                    AML, salvo acumulación mencionada.
                                    <br />
                                    2. Límites de Transacción:
                                    <br />
                                    Limitamos las operaciones a un máximo de 75,000 soles diarios y
                                    un mínimo de 50 soles, con ajustes para algunas criptomonedas
                                    que requieren montos mínimos más altos debido a costos de red.
                                    <br />
                                    3. Tasas de Cambio:
                                    <br />
                                    Las tasas de cambio se determinan basándonos en el análisis del
                                    mercado reciente, con actualización cada 5 minutos. Utilizamos
                                    Coinmarketcap.com como fuente de referencia para los valores de
                                    mercado.
                                    <br />
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    IDENTIFICACIÓN Y VERIFICACIÓN DE CLIENTES
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Procedimientos KYC:
                                    <br />
                                    Requerimos documentación de identidad válida (DNI, pasaporte,
                                    carnet de identificación extranjera) según nuestra política de
                                    prevención del delito.
                                    <br />
                                    2. Clientes Políticamente Expuestos (PEP):
                                    <br />
                                    Implementamos procedimientos específicos para la identificación
                                    y gestión de clientes PEP, considerándolos de alto riesgo
                                    independientemente del monto de sus operaciones.
                                    <br />
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    SEGURIDAD Y PROTECCIÓN DE DATOS
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Medidas de Seguridad:
                                    <br />
                                    Garantizamos la protección y privacidad de los datos del cliente
                                    conforme a nuestra política de privacidad. Como no custodiamos
                                    criptomonedas, facilitamos pagos en un promedio de 5 minutos, y
                                    hasta 45 minutos para montos superiores a 10,000 soles, con
                                    posibilidad de presentar quejas en nuestras plataformas
                                    digitales en caso de demoras.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    RESPONSABILIDADES Y LIMITACIONES
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Obligaciones de TREKO:
                                    <br />
                                    Nos comprometemos a respetar las cotizaciones válidas por 5
                                    minutos y realizar pagos dentro de los 45 minutos siguientes,
                                    manteniendo un tiempo promedio de operación de 5 minutos.
                                    <br />
                                    2. Restricciones de Servicio:
                                    <br />
                                    No aceptamos pagos con tarjeta de crédito ni operaciones
                                    superiores a 75,000 soles, y requerimos transferencias bancarias
                                    para montos superiores a 700 soles. Aceptamos depósitos en
                                    efectivo por cajero o agente, en cumplimiento de nuestras
                                    políticas KYC y AML, solo para montos menores a 700 soles.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    RESOLUCIÓN DE DISPUTAS
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Procedimiento de Gestión:
                                    <br />
                                    No actuamos como árbitros en disputas entre partes externas.
                                    Evaluamos la atención al cliente mediante un sistema de
                                    puntuación y gestionamos quejas a través de nuestro sistema de
                                    soporte.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    CUMPLIMIENTO REGULATORIO
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Adherencia a Normativas:
                                    <br />
                                    Cumplimos con las regulaciones locales e internacionales
                                    aplicables a las casas de cambio de criptomonedas, detalladas en
                                    nuestra política de prevención del delito y otras disposiciones
                                    legales pertinentes.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    MODIFICACIONES Y NOTIFICACIONES
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Actualizaciones de Términos:
                                    <br />
                                    Este documento se actualiza anualmente y ante cambios en
                                    nuestros servicios o procesos, notificados en nuestra página web
                                    con versión foliada y codificada por número de versión.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    USO ACEPTABLE DE LOS SERVICIOS
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Política de Uso:
                                    <br />
                                    No aplicamos restricciones adicionales más allá de las
                                    establecidas en nuestras políticas de prevención del delito y
                                    protección de datos del cliente.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    CANCELACIÓN DE SERVICIOS:
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Causas de Cancelación:
                                    <br />
                                    Nos reservamos el derecho de cancelar o suspender servicios por
                                    incumplimiento de términos y condiciones, maltrato a nuestros
                                    colaboradores, o por motivos sancionados en nuestras políticas
                                    de prevención del delito y protección de datos del cliente.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    JURISDICCIÓN Y LEY APLICABLE
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    1. Jurisdicción Competente:
                                    <br />
                                    Las disputas legales se resolverán exclusivamente bajo la
                                    jurisdicción de los tribunales de Arequipa, Perú, de acuerdo con
                                    las leyes peruanas aplicables.
                                    <br />
                                </p>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Estos términos y condiciones constituyen un acuerdo legal entre
                                    TREKO y sus clientes para el uso de nuestros servicios de compra
                                    y venta de criptomonedas. Para más información, consulte nuestra
                                    Política de Prevención del Delito y la Política de Protección y
                                    Privacidad de Datos del Cliente.
                                </p>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Fecha de última actualización: 01 de julio del 2024
                                </p>
                            </div>
                        </Container>
                        <Container className="wow fadeInUp relative -mt-[110px]">
                            <div
                                id="datos"
                                className="wow fadeInUp text-primary space-y-8 mt-28 mb-16 px-9 py-9"
                            >
                                <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                                    POLÍTICA DE PROTECCIÓN Y PRIVACIDAD DE DATOS DE LOS CLIENTES
                                </h4>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    CUMPLIMIENTO LEGAL Y REGULATORIO
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    GDPR y otras regulaciones: <br />
                                    TREKO opera únicamente en Perú, aunque personas extranjeras con
                                    cuentas peruanas pueden interactuar con nuestros servicios.
                                    Cumplimos con las leyes y regulaciones locales de protección de
                                    datos, asegurando que todos los datos recopilados sean tratados
                                    con la máxima confidencialidad y seguridad.
                                    <br />
                                    AML/KYC:
                                    <br />
                                    Implementamos una Politica de Prevencipon del Delito en la cual
                                    están detallados nuestros procesos de Anti-Lavado de Dinero
                                    (AML) y Conoce a tu Cliente (KYC) para verificar la identidad de
                                    los clientes y prevenir el fraude. Los procedimientos
                                    específicos incluyen:
                                    <br />
                                    Procedimientos KYC (Conoce a tu Cliente):
                                    <br />
                                    - Identificación del Cliente: Recolección de datos personales y
                                    documentación de identificación válida, verificando su
                                    autenticidad a través de fuentes confiables.
                                    <br />
                                    - Perfil del Cliente: Evaluación del perfil financiero,
                                    incluyendo la fuente de fondos y el propósito de las
                                    transacciones. Monitoreo continuo para identificar cambios en el
                                    comportamiento del cliente que puedan indicar riesgos.
                                    <br />
                                    - Implementación de un Sistema de Evaluación de Riesgos: Basado
                                    en el perfil laboral y el monto de las operaciones, clasificando
                                    los clientes en riesgos bajo, moderado y alto.
                                    <br />
                                    - Gestión de Personas Políticamente Expuestas (PEP):
                                    Identificación y monitoreo continuo de PEP, aplicando medidas
                                    adicionales de debida diligencia y revisión regular de sus
                                    perfiles y transacciones.
                                    <br />
                                    - Registro y Almacenamiento de Datos: Mantenimiento de registros
                                    precisos y actualizados de la información del cliente,
                                    protegidos contra accesos no autorizados conforme a las leyes de
                                    protección de datos.
                                    <br />
                                    Procedimiento AML (Prevención de Blanqueo de Capitales):
                                    <br />
                                    - Activación del Procedimiento: Para operaciones acumuladas en
                                    un mes que sumen más de 50,000 soles o una sola operación igual
                                    o mayor a 10,000 soles.
                                    <br />
                                    - Identificación del Origen de los Fondos: Recolección y
                                    verificación de datos del origen del dinero.
                                    <br />
                                    - Monitoreo de Transacciones: Sistemas para monitorear y
                                    analizar transacciones en tiempo real, identificando patrones
                                    inusuales.
                                    <br />
                                    - Reporte de Actividades Sospechosas: Procedimientos para
                                    reportar actividades sospechosas a las autoridades competentes,
                                    capacitando al personal en esta identificación.
                                    <br />
                                    - Debida Diligencia Reforzada: Medidas adicionales de
                                    verificación en casos de alto riesgo, evaluación continua de
                                    riesgos.
                                    <br />
                                    - Colaboración con Entidades Regulatorias: Cooperación con
                                    autoridades y cumplimiento de solicitudes de información en
                                    investigaciones.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    DECLARACIÓN DE PRIVACIDAD CLARA
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    TREKO recopila datos personales, de ingresos y profesión de los
                                    clientes, utilizados para identificar riesgos de lavado de
                                    activos y cumplir con la normativa legal. Nuestra política de
                                    privacidad explica detalladamente cómo se recopilan, usan,
                                    almacenan y protegen estos datos.
                                    <br />
                                    Consentimiento informado:
                                    <br />
                                    El consentimiento explícito de los clientes se obtiene a través
                                    de un formulario donde se detalla el uso de sus datos con fines
                                    legales, asegurando el cumplimiento de la normativa legal
                                    vigente.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    SEGURIDAD DE LA INFORMACIÓN{" "}
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Cifrado de datos:
                                    <br />
                                    TREKO utiliza el cifrado AES-256 para proteger los datos en
                                    reposo y TLS (Transport Layer Security) para asegurar la
                                    transmisión de datos entre usuarios y servidores, garantizando
                                    la integridad y confidencialidad de los datos durante su
                                    transferencia.
                                    <br />
                                    Autenticación multifactor (MFA):
                                    <br />
                                    Aunque no es necesario para las cuentas bancarias de los
                                    clientes ni billeteras electrónicas, TREKO implementa MFA para
                                    acceder a las cuentas que almacenan los datos de nuestros
                                    clientes, restringiendo el acceso solo al personal autorizado.
                                    <br />
                                    Control de acceso:
                                    <br />
                                    Se implementan permisos basados en roles y métodos de MFA para
                                    el personal autorizado, quienes se encargan del proceso KYC y el
                                    área legal que supervisa el cumplimiento, comprometiéndose a
                                    custodiar adecuadamente las contraseñas.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    SEGURIDAD DE LA INFRAESTRUCTURA
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Seguridad de la red:
                                    <br />
                                    TREKO implementa Sistemas de Detección y Prevención de Intrusos
                                    (IDS/IPS) para proteger la red de la empresa, asegurando la
                                    detección y bloqueo de tráfico malicioso.
                                    <br />
                                    Actualizaciones y parches:
                                    <br />
                                    Las actualizaciones y parches de seguridad se realizan de
                                    acuerdo a la criticidad y frecuencia establecida: inmediatas
                                    para críticas, mensuales para seguridad regular, trimestrales
                                    para software y sistemas operativos, anuales para auditorías,
                                    semanales para software de seguridad, semestrales para hardware
                                    y firmware, y pruebas de penetración.
                                    <br />
                                    Respaldo de datos:
                                    <br />
                                    El respaldo de datos se realiza mensualmente y se almacenan en
                                    un disco duro externo custodiado por el área legal.
                                    <br />
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    CONCIENCIA Y CAPACITACIÓN{" "}
                                </h3>

                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Formación del personal:
                                    <br />
                                    Los empleados son capacitados al momento de su incorporación y
                                    de manera mensual sobre las mejores prácticas de seguridad de
                                    datos y cumplimiento de políticas de privacidad.
                                    <br />
                                    Simulacros de seguridad:
                                    <br />
                                    Se realizan simulacros de seguridad de manera bimestral para
                                    identificar y mitigar posibles vulnerabilidades.
                                    <br />
                                </p>

                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    MONITOREO Y AUDITORÍA
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Monitoreo continuo:
                                    <br />
                                    Utilizamos sistemas IDS/IPS para el monitoreo continuo de la
                                    red, detectando y respondiendo a posibles incidentes de
                                    seguridad.
                                    <br />
                                    Auditorías regulares:
                                    <br />
                                    TREKO realiza auditorías internas semestrales y contrata
                                    especialistas para auditorías externas anuales, asegurando el
                                    cumplimiento de las medidas de seguridad y políticas de
                                    privacidad.
                                    <br />
                                </p>
                                <h3 className="text-l text-center lg:text-l text-primary font-bold">
                                    GESTIÓN DE INCIDENTES
                                </h3>
                                <p className="text-sm text-primary-light sm:text-base font-medium">
                                    Plan de respuesta a incidentes:
                                    <br />
                                    TREKO ha desarrollado un Plan de Respuesta a Incidentes (PRI)
                                    que incluye:
                                    <br />
                                    Equipo de Respuesta a Incidentes (IRT): Integrado por el área de
                                    TI y el equipo encargado de la protección de datos.
                                    <br />
                                    - Políticas y Procedimientos: Incluidas en la política de
                                    protección y seguridad de datos.
                                    <br />
                                    - Infraestructura y Herramientas: Implementación de IDS/IPS y
                                    canales de comunicación internos.
                                    <br />
                                    - Identificación de Incidentes: Uso de herramientas de monitoreo
                                    para detectar actividades sospechosas.
                                    <br />
                                    - Contención y Erradicación: Aislamiento de sistemas afectados,
                                    aplicación de parches y eliminación de artefactos maliciosos.
                                    <br />
                                    - Recuperación: Reintegración de sistemas y monitoreo posterior.
                                    <br />
                                    - Lecciones Aprendidas: Análisis post-incidente y actualización
                                    del plan basado en las lecciones aprendidas.
                                    <br />
                                    - Documentación y Reporte: Mantenimiento de registros detallados
                                    y preparación de reportes regulares.
                                    <br />
                                    Notificación de violaciones:
                                    <br />
                                    En caso de una violación de datos, TREKO notifica a las
                                    autoridades competentes y a los clientes afectados a través del
                                    área de TI, asegurando una comunicación eficiente y oportuna.
                                    <br />
                                    Implementar estas medidas garantiza que los datos de los
                                    clientes estén protegidos adecuadamente y que TREKO cumpla con
                                    las leyes y regulaciones aplicables, manteniendo la confianza y
                                    la integridad de sus operaciones en el mercado de criptomonedas.
                                    <br />
                                </p>
                            </div>
                        </Container>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Legal;
