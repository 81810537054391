import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Legal from "./pages/Legal";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route
                    path="/login"
                    element={
                        <Guest>
                            <Login />
                        </Guest>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <Guest>
                            <Register />
                        </Guest>
                    }
                />
                <Route
                    path="/forgot-password"
                    element={
                        <Guest>
                            <ForgotPassword />
                        </Guest>
                    }
                />
                <Route
                    path="/reset-password"
                    element={
                        <Guest>
                            <ResetPassword />
                        </Guest>
                    }
                />

                <Route
                    path="/dashboard"
                    element={
                        <Auth>
                            <Dashboard />
                        </Auth>
                    }
                /> */}

                <Route path="/legal" element={<Legal />} />
                {/* <Route
                    path="/reload-account"
                    element={
                        <Auth>
                            <ReloadAccount />
                        </Auth>
                    }
                /> */}
                <Route path="/*" element={<Home />} />
            </Routes>
        </Router>
    );
};

export default App;
