// import { Link } from "react-router-dom";

import TransferForm from "../components/TransferForm";
import { Container } from "../components/utils";
// import { checkUser } from "../helpers";

const Banner = () => {
    return (
        <section
            className="w-full overflow-hidden bg-[#f2f3f9] wow fadeInUp"
            style={{ backgroundImage: "url('/images/trekobg.svg')", backgroundSize: "contain" }}
        >
            <div
                id="home"
                className="relative bg-auto bg-no-repeat bg-[top_120px_right] md:bg-[top_70px_right] lg:bg-[top_100px_right]"
            >
                <div className="pt-[150px] pb-[250px] xl:pb-[200px]">
                    <Container className="pt-5 flex flex-col md:flex-row md:items-center space-y-6 md:space-y-0 md:space-x-4 lg:space-x-0 lg:justify-between relative z-10">
                        <div>
                            <h5 className="font-bold lg:text-xl xl:text-2xl text-primary-dark mb-3">
                                Con la confianza del mejor equipo
                            </h5>
                            <h1 className="text-5xl lg:text-6xl xl:text-6xl font-bold text-primary">
                                Cambia más de 500,
                                <br /> criptomonedas, de
                                <br /> manera fácil.
                            </h1>
                            <p className="lg:text-xl xl:text-2xl text-primary-dark my-8">
                                Envía, recibe y solicita criptomonedas de forma rápida{" "}
                                <br className="hidden md:block hidden xl:block" />
                                y sencilla con TREKO.
                                <br className="hidden md:block xl:hidden" /> Disfruta de soluciones
                                personalizadas que se adaptan{" "}
                                <br className="hidden md:block xl:hidden" /> a tus necesidades.
                            </p>

                            {/* <div className="flex items-center space-x-4">
                                {!checkUser() && (
                                    <Link
                                        to="/register"
                                        className="lg:text-lg truncate transition-all duration-300 px-3 lg:px-4 xl:px-8 font-medium py-3 bg-primary text-white rounded-md focus:outline-none hover:bg-primary-light focus:ring focus:border-primary-light focus:ring-primary-light"
                                    >
                                        Abre una cuenta
                                    </Link>
                                )}

                                <Link
                                    to="/#how-it-work"
                                    className="lg:text-lg truncate transition-all duration-300 px-3 lg:px-4 xl:px-8 font-medium py-3 text-primary border border-primary rounded-md focus:outline-none hover:text-white hover:bg-primary focus:ring focus:border-primary-light focus:ring-primary-light"
                                >
                                    Como trabajamos
                                </Link>
                            </div> */}
                        </div>

                        <div className="bg-white border border-gray-100 rounded-lg shadow-xl p-4 md:p-5 w-full md:w-1/2 lg:w-[50%] xl:w-[50%]">
                            <div className="space-y-8 lg:space-y-10">
                                <TransferForm />
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </section>
    );
};

export default Banner;
