import { Link } from "react-router-dom";

import { Container } from "../components/utils";

const NavLegal = () => {
    const handleRedirect = href => {
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <nav className="text-primary-dark p-2 sm:p-0 text-base xl:text-lg font-medium bg-white py-4 lg:py-[1.1rem] xl:py-8 border-b shadow-sm fixed w-full z-50">
            <Container className="flex items-center justify-between py-2 xl:py-0">
                <div className="flex items-center space-x-12">
                    <Link to="/" className="flex items-center space-x-3">
                        <img src={"/logo.svg"} className="h-14 w-14" alt="" />
                        <div>
                            <p className="inline text-xl md:text-2xl uppercase font-bold leading-[0.5rem]">
                                Treko<span className="font-[300]"></span>
                            </p>
                            <div className="flex items-center space-x-0.5 leading-[0.5rem]">
                                <span className="text-[0.62rem] font-bold text-primary uppercase leading-[0.5rem]">
                                    Change
                                </span>
                                {/* <hr className="w-5 border-sky-600" /> */}
                            </div>
                        </div>
                    </Link>

                    <div className="space-x-4 xl:space-x-8 hidden md:block">
                        <Link
                            to="/"
                            className="transition-all duration-300 font-medium py-2 xl:py-3 hover:text-primary"
                        >
                            Home
                        </Link>
                        <Link
                            onClick={() => handleRedirect("kyc")}
                            to="#kyc"
                            className="transition-all duration-300 font-medium py-2 xl:py-3 hover:text-primary"
                        >
                            Politica AML/KYC
                        </Link>
                        <Link
                            onClick={() => handleRedirect("terms")}
                            to="#terms"
                            className="transition-all duration-300 font-medium py-2 xl:py-3 hover:text-primary"
                        >
                            Terminos y Condiciones
                        </Link>
                        <Link
                            onClick={() => handleRedirect("datos")}
                            to="#datos"
                            className="transition-all duration-300 font-medium py-2 xl:py-3 hover:text-primary"
                        >
                            Politica de Protección de Datos
                        </Link>
                    </div>
                </div>
            </Container>
        </nav>
    );
};

export default NavLegal;
