import { Menu, Transition, Combobox } from "@headlessui/react";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { FiDivideCircle, FiXCircle } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { TbPercentage } from "react-icons/tb";
import { useTimer } from "react-timer-hook";

import { Loader } from "./utils";

const TransferForm = () => {
    const defaultMessage = {
        amount: [],
        destAmount: [],
        phoneNumber: []
    };
    const time = new Date();
    time.setSeconds(time.getSeconds() + 300);
    const { seconds, minutes, restart } = useTimer({
        autoStart: true,
        expiryTimestamp: time,
        onExpire: () => fetchExchangeRate()
    });

    const [loading, setLoading] = useState(false);
    const [rates, setRates] = useState(null);
    const [country, setCountry] = useState(null);
    const [codes, setCodes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [originCurrency, setOrigingCurrency] = useState(null);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [networks, setNetworks] = useState(null);
    const [destCurrency, setDestCurrency] = useState(null);
    const [amount, setAmount] = useState("");
    const [destAmount, setDestAmount] = useState("");
    const lastUpdated = useRef("");
    const [feature, setFeature] = useState([]);
    const [errorMessage, setErrorMessage] = useState(defaultMessage);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [query, setQuery] = useState("");
    const [queryDest, setQueryDest] = useState("");
    const get = (obj, key, defaultValue) => (key in (obj || {}) ? obj[key] : defaultValue);

    const filteredCurrency =
        query === ""
            ? currencies
            : currencies.filter(currency => {
                  return currency.code.toLowerCase().includes(query.toLowerCase());
              });

    const filteredCurrencyDest =
        queryDest === ""
            ? currencies
            : currencies.filter(currency => {
                  return currency.code.toLowerCase().includes(queryDest.toLowerCase());
              });

    const fetchData = async () => {
        setLoading(true);
        try {
            let url = "https://treko.pe/api/dashboard/";
            const response = await axios.get(url);
            setCountries(response.data.data.countries);
            setCountry(response.data.data.countries[0]);
            setErrorMessage(defaultMessage);
            setCurrencies(response.data.data.currencies);
            setNetworks(response.data.data.networks);
            if (!originCurrency) {
                setOrigingCurrency(response.data.data.currencies[0]);
            }
            if (!originCurrency) {
                setDestCurrency(response.data.data.currencies.filter(x => x.code == "USDT")[0]);
            }
            setFeature(response.data.data.feature.sort((a, b) => a.sequence - b.sequence));
        } catch (error) {
            console.error("Error fetching exchange rate:", error);
            setLoading(false);
        }
        setLoading(false);
    };

    const fetchExchangeRate = async () => {
        setLoading(true);
        try {
            let url = "https://treko.pe/api/rates/";
            if (codes) {
                url = `${url}?codes=${codes}`;
            }
            const response = await axios.get(url);
            setRates(response.data.data.rates);
        } catch (error) {
            console.error("Error fetching exchange rate:", error);
            setLoading(false);
        }
        setLoading(false);
        let time = new Date();
        time.setSeconds(time.getSeconds() + 300);
        restart(time);
    };

    const fetchExchangeRateSec = async codes_str => {
        setLoading(true);
        try {
            let url = "https://treko.pe/api/rates/";
            if (codes_str) {
                url = `${url}?codes=${codes_str}`;
            }
            const response = await axios.get(url);
            setRates({ ...rates, ...response.data.data.rates });
        } catch (error) {
            console.error("Error fetching exchange rate:", error);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchExchangeRate();
        fetchData();
    }, []);

    const changeCodes = new_code => {
        const updatedCodes = [...codes, new_code];
        setCodes(updatedCodes);
        const flag = updatedCodes.some(item => Object.keys(rates).includes(item));
        if (!flag) {
            fetchExchangeRateSec([originCurrency.code, destCurrency.code, new_code]);
        }
    };

    // useEffect(() => {
    // async function fetchDataCode(co) {
    //     let url = "https://treko.pe/api/dashboard/";
    //     if (co) {
    //         url = `${url}?codes=${co}`;
    //     }
    //     const response = await axios.get(url);
    //     setRates(response.data.data.rates);
    // }
    //     if (codes.length > 0 && !Object.keys(rates).includes(codes[-1])) {
    //         fetchDataCode();
    //     }
    // }, [codes]);

    const sendWhatsApp = () => {
        let msg = `Hola, quiero realizar una operacion de cambio de ${originCurrency.code} a ${destCurrency.code}, enviaria ${amount} ${originCurrency.code}. Muchas gracias.`;
        let phoneWithCountryCode = "+51972308372";
        const url = `https://wa.me/${phoneWithCountryCode}?text=${encodeURIComponent(msg)}`;
        window.open(url, "_blank");
    };

    const submit = () => {
        sendWhatsApp();
    };

    const submittoServer = async () => {
        if (!phoneNumber && !countries) {
            setErrorMessage(defaultMessage);
        }
        setLoading(true);
        try {
            await axios.post("https://treko.pe/api/quotation/", {
                currency: originCurrency.code,
                currency_to: destCurrency.code,
                user_phone: phoneNumber || 0,
                price: destAmount || 0,
                tradable_quantity: amount
            });
        } catch (error) {
            setErrorMessage({
                ...defaultMessage,
                phoneNumber: [
                    "No pudimos enviar el mensaje, por favor comunicate con nosotros con el boton de abajo"
                ]
            });
            setLoading(false);
        }
        setPhoneNumber("");
        setLoading(false);
    };

    useEffect(() => {
        if (lastUpdated.current === "original") {
            lastUpdated.current = "";
            let newFinalAmount = amount;
            feature
                .sort((a, b) => a.sequence - b.sequence)
                .forEach(e => {
                    if (displayFeature(e)) {
                        if (e.type === "minus") {
                            newFinalAmount = parseFloat(newFinalAmount) - parseFloat(e.display);
                        }
                        if (e.type === "per") {
                            newFinalAmount =
                                parseFloat(newFinalAmount) *
                                (parseFloat(1) - parseFloat(e.display) / parseFloat(100));
                        }
                        if (e.type === "divide") {
                            if (destCurrency.is_fiat) {
                                newFinalAmount = parseFloat(
                                    newFinalAmount *
                                        get(
                                            rates,
                                            `${originCurrency?.code}${destCurrency?.code}`,
                                            1
                                        )
                                );
                            } else {
                                newFinalAmount = parseFloat(
                                    newFinalAmount /
                                        get(
                                            rates,
                                            `${originCurrency?.code}${destCurrency?.code}`,
                                            1
                                        )
                                );
                            }
                        }
                    }
                });
            if (originCurrency.is_fiat && selectedNetwork) {
                newFinalAmount = parseFloat(newFinalAmount - selectedNetwork.fee_withdraw);
            }
            let fixed_amount = 8;
            if (destCurrency.is_fiat) {
                fixed_amount = 2;
            }
            newFinalAmount = newFinalAmount.toFixed(fixed_amount);
            if (newFinalAmount !== destAmount && !isNaN(newFinalAmount)) {
                setDestAmount(Math.max(0, newFinalAmount));
            }
        }
    }, [amount, destAmount, originCurrency, destCurrency, selectedNetwork]);

    useEffect(() => {
        if (lastUpdated.current === "final") {
            let newOriginalAmount = destAmount;
            if (originCurrency.is_fiat && selectedNetwork) {
                newOriginalAmount =
                    parseFloat(newOriginalAmount) + parseFloat(selectedNetwork.fee_withdraw);
            }
            feature
                .slice()
                .sort((a, b) => a.sequence - b.sequence)
                .reverse()
                .forEach(e => {
                    if (displayFeature(e)) {
                        if (e.type === "minus") {
                            newOriginalAmount =
                                parseFloat(newOriginalAmount) + parseFloat(e.display);
                        }
                        if (e.type === "per") {
                            newOriginalAmount =
                                (parseFloat(newOriginalAmount) * parseFloat(100)) /
                                (100 - parseFloat(e.display));
                        }
                        if (e.type === "divide") {
                            if (destCurrency.is_fiat) {
                                newOriginalAmount = parseFloat(
                                    newOriginalAmount /
                                        get(
                                            rates,
                                            `${originCurrency?.code}${destCurrency?.code}`,
                                            0
                                        )
                                );
                            } else {
                                newOriginalAmount = parseFloat(
                                    newOriginalAmount *
                                        get(
                                            rates,
                                            `${originCurrency?.code}${destCurrency?.code}`,
                                            0
                                        )
                                );
                            }
                        }
                    }
                });
            let fixed_amount = 8;
            if (originCurrency.is_fiat) {
                fixed_amount = 2;
            }
            newOriginalAmount = newOriginalAmount.toFixed(fixed_amount);
            if (newOriginalAmount !== amount) {
                setAmount(Math.max(newOriginalAmount, 0));
            }
        }
    }, [amount, destAmount, originCurrency, destCurrency, selectedNetwork]);

    const handleSelectedNetworkChange = e => {
        lastUpdated.current = "original";
        setSelectedNetwork(e);
    };

    const handleAmountChange = e => {
        lastUpdated.current = "original";
        setAmount(e);
    };

    const handleDestAmountChange = e => {
        lastUpdated.current = "final";
        setDestAmount(e);
    };

    const handleOriginalCurrencyChange = e => {
        changeCodes(e.code);
        // fetchDataCode([...codes, e.code]);
        if (e.code === destCurrency.code) {
            setDestCurrency(originCurrency);
        }
        if (e.is_fiat && destCurrency.is_fiat) {
            setDestCurrency(currencies.filter(item => !item.is_fiat)[0]);
        }
        // if (!e.is_fiat && !destCurrency.is_fiat) {
        //     setDestCurrency(currencies.filter(item => item.is_fiat)[0]);
        // }
        lastUpdated.current = "original";
        setOrigingCurrency(e);
    };

    const handleDestCurrencyChange = e => {
        changeCodes(e.code);
        // fetchDataCode([...codes, e.code]);
        if (e.code !== selectedNetwork?.currency) {
            setSelectedNetwork(null);
            // let options = networks.filter(item => item.currency == e.code);
            // if (options) {
            //     setSelectedNetwork(options[0]);
            // }
        }
        if (e.code === originCurrency.code) {
            setOrigingCurrency(destCurrency);
        }
        if (e.is_fiat && originCurrency.is_fiat) {
            setOrigingCurrency(currencies.filter(item => !item.is_fiat)[0]);
        }
        // if (!e.is_fiat && !originCurrency.is_fiat) {
        //     setOrigingCurrency(currencies.filter(item => item.is_fiat)[0]);
        // }
        lastUpdated.current = "original";
        setDestCurrency(e);
    };

    const displayFeature = feat => {
        if (feat.currencies.length > 0 && !feat.currencies.includes(originCurrency.code)) {
            return false;
        }
        if (feat.min_value && feat.min_value > amount) {
            return false;
        }
        if (feat.max_value && feat.max_value <= amount) {
            return false;
        }
        return true;
    };

    const getFeatureIcon = feat => {
        if (feat.type === "minus") {
            return <IoIosRemoveCircleOutline size="20" />;
        }
        if (feat.type === "divide") {
            if (destCurrency?.is_fiat) {
                return <FiXCircle size="19" />;
            }
            return <FiDivideCircle size="19" />;
        }
        return <TbPercentage size="19" />;
    };

    const getFeatureDisplay = feat => {
        if (feat.type === "divide") {
            return parseFloat(get(rates, `${originCurrency.code}${destCurrency.code}`, 0)).toFixed(
                3
            );
        }
        return feat.display;
    };

    return (
        <Transition
            show={!loading}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div>
                <div className="rounded-lg bg-primary p-4 lg:p-5">
                    <div className="text-center text-secondary text-bold text-sm">
                        <span>{minutes}</span>:<span>{seconds}</span>
                    </div>
                    <div className="flex space-x-3 items-center">
                        <div className="w-2/3 relative">
                            <h6 className="text-sm text-white">Envias</h6>
                            <input
                                type="number"
                                className={`p-0 w-full bg-primary border-0 border-b-2 ${
                                    errorMessage.amount.length
                                        ? "border-red-600 focus:border-red-600"
                                        : "border-gray-300 focus:border-primary"
                                } text-white placeholder-white placeholder-opacity-80 font-bold text-xl lg:text-2xl focus:ring-0`}
                                placeholder="0,00"
                                value={amount}
                                onChange={e => handleAmountChange(e.target.value)}
                            />
                            {errorMessage.amount.length > 0 && (
                                <p className="absolute text-sm text-red-600">
                                    {errorMessage.amount}
                                </p>
                            )}
                        </div>

                        <div className="relative w-1/4 md:w-1/3">
                            <Combobox
                                value={originCurrency}
                                onChange={handleOriginalCurrencyChange}
                            >
                                <Combobox.Input
                                    onChange={event => setQuery(event.target.value)}
                                    displayValue={currency => currency?.code}
                                    className="w-full px-3 shadow-sm flex items-center space-x-2 justify-center text-primary-dark bg-white py-3 md:py-4 rounded-lg border-white md:text-lg font-bold uppercase font-semibold hover:text-white hover:bg-primary-light"
                                />
                                <Combobox.Options className="absolute w-full bg-white py-2 md:py-3 rounded-lg">
                                    {filteredCurrency.map(currency => (
                                        <Combobox.Option
                                            key={currency?.code}
                                            value={currency}
                                            className="w-full px-3 shadow-sm flex items-center space-x-2 justify-center text-primary-dark bg-white py-2 md:py-3 rounded-lg md:text-sm font-bold uppercase font-semibold hover:text-white hover:bg-primary-light"
                                            // className="ui-active:bg-white ui-active:text-primary ui-not-active:bg-primary-light ui-not-active:text-black"
                                        >
                                            {currency?.code}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            </Combobox>
                        </div>
                        {/* 
                        <Menu>
                            {({ open }) => (
                                <div className="relative w-1/4 md:w-1/3">
                                    <Menu.Button className="w-full px-3 shadow-sm flex items-center space-x-2 justify-center text-primary-dark bg-white py-3 md:py-4 rounded-lg md:text-lg font-bold uppercase font-semibold hover:text-white hover:bg-primary-light">
                                        <span>{originCurrency?.code}</span>
                                        <HiChevronRight
                                            className={`transition-all duration-300 ${
                                                open ? "rotate-90" : ""
                                            } h-6 w-6`}
                                        />
                                    </Menu.Button>

                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Menu.Items>
                                            <div className="absolute right-0 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow max-h-80 overflow-y-auto">
                                                {currencies.map(currency => (
                                                    <Menu.Item
                                                        key={currency.code}
                                                        as={"button"}
                                                        onClick={() =>
                                                            handleOriginalCurrencyChange(currency)
                                                        }
                                                        className="flex items-center space-x-3 px-3 py-2.5 text-sm hover:text-primary"
                                                    >
                                                        <span>{currency.code}</span>
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </div>
                            )}
                        </Menu> */}
                    </div>
                </div>

                <div className="items-center text-primary text-sm lg:p-5">
                    {feature.map(
                        feat =>
                            displayFeature(feat) && (
                                <div key={feat.id} className="flex items-center font-bold">
                                    <div className="w-1/12">{getFeatureIcon(feat)}</div>
                                    <div className="w-4/12">
                                        <span>{getFeatureDisplay(feat)}</span>
                                    </div>
                                    <div className="w-7/12">{feat.label}</div>
                                </div>
                            )
                    )}
                    {originCurrency?.is_fiat && (
                        <div className="flex items-center font-bold">
                            <div className="w-1/12">
                                <IoIosRemoveCircleOutline size="19" />
                            </div>
                            <div className="w-4/12">
                                <span>{selectedNetwork?.fee_withdraw || 0.0}</span>
                            </div>
                            <div className="w-7/12">
                                <Menu>
                                    {({ open }) => (
                                        <div>
                                            <Menu.Button className="w-full shadow-sm flex border border-primary rounded-md">
                                                <span>
                                                    {selectedNetwork?.network
                                                        ? selectedNetwork?.network
                                                        : "Selecciona tu Red"}
                                                </span>
                                                <HiChevronRight
                                                    className={`transition-all duration-300 ${
                                                        open ? "rotate-90" : ""
                                                    } h-6 w-6`}
                                                />
                                            </Menu.Button>

                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <Menu.Items>
                                                    <div className="absolute right-0 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow max-h-80 overflow-y-auto">
                                                        {networks?.map(
                                                            network =>
                                                                network.currency ==
                                                                    destCurrency.code && (
                                                                    <Menu.Item
                                                                        key={network.id}
                                                                        as={"button"}
                                                                        onClick={() =>
                                                                            handleSelectedNetworkChange(
                                                                                network
                                                                            )
                                                                        }
                                                                        className="flex items-center space-x-3 px-3 py-2.5 text-sm hover:text-primary"
                                                                    >
                                                                        <span>
                                                                            {network?.fee_withdraw}
                                                                            {` ${destCurrency.code} - `}
                                                                            {network?.network}
                                                                        </span>
                                                                    </Menu.Item>
                                                                )
                                                        )}
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </div>
                                    )}
                                </Menu>
                            </div>
                            {/* <div className="w-4/12">
                                <span>{selectedNetwork?.fee_withdraw}</span>
                            </div>
                            <div className="w-7/12">{selectedNetwork?.network}</div> */}
                        </div>
                    )}
                </div>

                <div className="rounded-lg bg-primary p-4 lg:p-5">
                    <div className="flex space-x-3 items-center">
                        <div className="w-2/3">
                            <h6 className="text-sm text-white">Recibes</h6>
                            <input
                                type="number"
                                className={`p-0 w-full bg-primary border-0 border-b-2 ${
                                    errorMessage.destAmount.length
                                        ? "border-red-600 focus:border-red-600"
                                        : "border-gray-300 focus:border-primary-800"
                                } text-white placeholder-secondary placeholder-opacity-80 font-bold text-xl lg:text-2xl focus:ring-0`}
                                placeholder="0,00"
                                value={destAmount}
                                onChange={e => handleDestAmountChange(e.target.value)}
                            />
                            {errorMessage.destAmount.length > 0 && (
                                <p className="absolute text-sm text-red-600">
                                    {errorMessage.destAmount}
                                </p>
                            )}
                        </div>

                        <div className="w-1/4 md:w-1/3">
                            <Combobox value={destCurrency} onChange={handleDestCurrencyChange}>
                                <Combobox.Input
                                    onChange={event => setQueryDest(event.target.value)}
                                    displayValue={currency => currency?.code}
                                    className="w-full px-3 shadow-sm flex items-center space-x-2 justify-center text-primary-dark bg-white py-3 md:py-4 rounded-lg border-white md:text-lg font-bold uppercase font-semibold hover:text-white hover:bg-primary-light"
                                />
                                <Combobox.Options className="absolute px-3 bg-white py-3 md:py-4 rounded-lg">
                                    {filteredCurrencyDest.map(currency => (
                                        <Combobox.Option
                                            key={currency?.code}
                                            value={currency}
                                            className="w-full px-3 shadow-sm flex items-center space-x-2 justify-center text-primary-dark bg-white py-2 md:py-3 rounded-lg md:text-sm font-bold uppercase font-semibold hover:text-white hover:bg-primary-light"
                                            // className="ui-active:bg-white ui-active:text-primary ui-not-active:bg-primary-light ui-not-active:text-black"
                                        >
                                            {currency?.code}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            </Combobox>
                        </div>
                        {/* 
                        <Menu>
                            {({ open }) => (
                                <div className="w-1/4 md:w-1/3">
                                    <Menu.Button className="w-full px-3 shadow-sm flex items-center space-x-2 justify-center text-primary-dark bg-white py-3 md:py-4 rounded-lg md:text-lg font-bold uppercase font-semibold hover:text-white hover:bg-primary-light">
                                        <span>{destCurrency?.code}</span>
                                        <HiChevronRight
                                            className={`transition-all duration-300 ${
                                                open ? "rotate-90" : ""
                                            } h-6 w-6`}
                                        />
                                    </Menu.Button>

                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0"
                                    >
                                        <Menu.Items>
                                            <div className="absolute right-0 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow max-h-80 overflow-y-auto">
                                                {currencies.map(currency => (
                                                    <Menu.Item
                                                        key={currency.code}
                                                        as={"button"}
                                                        onClick={() =>
                                                            handleDestCurrencyChange(currency)
                                                        }
                                                        className="flex items-center space-x-3 px-3 py-2.5 text-sm hover:text-indigo-600"
                                                    >
                                                        <span>{currency.code}</span>
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </div>
                            )}
                        </Menu> */}
                    </div>
                </div>
            </div>
            <div className="space-y-3 text-primary items-center">
                <div className="flex px-3 py-3 space-x-3 items-center">
                    <div className="w-2/3">
                        <h6 className="text-sm text-primary">Nosotros nos ponemos en Contacto</h6>
                        <div className="flex">
                            <img
                                src={`/images/${country?.img}.svg`}
                                className="rounded-full h-6 w-6"
                                alt=""
                            />
                            <input
                                type="tel"
                                className={`p-0 w-full border-0 border-b-2 ${
                                    errorMessage.phoneNumber.length
                                        ? "border-red-600 focus:border-red-600"
                                        : "border-gray-300 focus:border-primary"
                                } text-primary-light placeholder-secondary placeholder-opacity-80 font-bold text-xl lg:text-2xl focus:ring-0`}
                                placeholder="987 654 321"
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        {errorMessage.phoneNumber.length > 0 && (
                            <p className="absolute text-sm text-red-600">
                                {errorMessage.phoneNumber}
                            </p>
                        )}
                    </div>
                    <Menu>
                        {({ open }) => (
                            <div className="w-1/4 md:w-1/3">
                                <Menu.Button
                                    onClick={() => submittoServer()}
                                    className="w-full shadow-sm flex items-center space-x-2 justify-center text-primary bg-white py-3 md:py-4 rounded-lg text-lg font-bold uppercase font-semibold hover:text-white hover:bg-primary-light"
                                >
                                    <HiChevronRight
                                        className={`transition-all duration-300 ${
                                            open ? "rotate-90" : ""
                                        } h-6 w-6`}
                                    />
                                </Menu.Button>
                                {/* <Menu.Button className="w-full shadow-sm flex items-center space-x-2 justify-center text-indigo-900 bg-white py-3 md:py-4 rounded-lg text-lg font-bold uppercase font-semibold">
                                    <img
                                        src={`/images/${country?.img}.svg`}
                                        className="rounded-full h-6 w-6"
                                        alt=""
                                    />
                                    <HiChevronRight
                                        className={`transition-all duration-300 ${
                                            open ? "rotate-90" : ""
                                        } h-6 w-6`}
                                    />
                                </Menu.Button>

                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Menu.Items>
                                        <div className="absolute right-0 z-10 px-2 py-1 mt-1 w-44 text-gray-600 bg-white border rounded-md shadow">
                                            {countries.map((item, index) => (
                                                <Menu.Item
                                                    key={index}
                                                    as={"button"}
                                                    className="flex items-center space-x-3 px-3 py-2 text-sm hover:text-indigo-600"
                                                    onClick={() => setCountry(item)}
                                                >
                                                    <img
                                                        src={`/images/${item.img}.svg`}
                                                        className="rounded-full h-5 w-5"
                                                        alt=""
                                                    />
                                                    <span>{item.text}</span>
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition> */}
                            </div>
                        )}
                    </Menu>
                </div>
                <h6 className="w-full flex items-center justify-center space-x-3 ">ó</h6>
                <button
                    onClick={submit}
                    className="w-full flex items-center justify-center space-x-3 transition-all block text-center duration-300 px-3 lg:px-4 xl:px-8 font-medium lg:text-lg py-3 bg-primary text-white rounded-md focus:outline-none hover:bg-primary-700 focus:ring focus:border-primary focus:ring-primary"
                >
                    {loading && <Loader color={"white"} />}
                    <span>
                        Comunicate Por WhatsApp
                        <br />
                        +51 972 308 372
                    </span>
                </button>
            </div>
        </Transition>
    );
};

export default TransferForm;
