import CountUp from "react-countup";
import { Helmet } from "react-helmet";

import { Container } from "../components/utils";
import Banner from "../layouts/Banner";
import Nav from "../layouts/Nav";

import Footer from "./Footer";

const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Treko Home - Casa de Cambio</title>
                <meta
                    name="description"
                    content="Treko pagina web de casa de cambio de criptomonedas."
                />
                <link rel="canonical" href="https://treko.pe/" />
            </Helmet>
            <Nav />

            <Banner />

            <Container className="wow fadeInUp relative -mt-[110px]">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 bg-white border border-gray-200 border-opacity-50 shadow-2xl rounded-xl">
                    <div className="text-center py-10 lg:py-16 space-y-3 text-primary font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-primary-light">
                            <CountUp end={500} />+
                        </h1>
                        <p className="text-sm lg:text-base">Criptomonedas Soportadas</p>
                    </div>

                    <div className="text-center py-10 lg:py-16 space-y-3 text-primary font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-primary-light">
                            PE
                        </h1>
                        <p className="text-sm lg:text-base">Disponibilidad en todo el Peru</p>
                    </div>

                    <div className="text-center py-10 lg:py-16 space-y-3 text-primary font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-primary-light">
                            <CountUp end={10} />+
                        </h1>
                        <p className="text-sm lg:text-base">Metodos de Pago</p>
                    </div>

                    <div className="text-center py-10 lg:py-16 space-y-3 text-primary font-medium">
                        <h1 className="text-5xl lg:text-6xl font-semibold text-primary-light">
                            9-20Hrs
                        </h1>
                        <p className="text-sm lg:text-base">Atención</p>
                    </div>
                </div>
            </Container>
            <Container className="wow fadeInUp relative -mt-[110px]">
                <div
                    id="how-it-work"
                    className="wow fadeInUp text-primary space-y-8 mt-28 mb-16 px-9 py-9"
                >
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        Como Trabajamos?
                    </h4>

                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        En Treko, nos dedicamos a ofrecer servicios de cambio de criptomonedas de
                        manera segura, rápida y confiable. Nuestro compromiso es proporcionar una
                        experiencia excepcional a nuestros clientes.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">Horario de Atención: </span>Atendemos de
                        lunes a sábados, de 9:00 a.m. a 8:00 p.m. (hora peruana). Los domingos y
                        feriados, nuestros servicios están disponibles de 11 am a 6 pm.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">Bancos Disponibles: </span>Trabajamos con las
                        principales entidades bancarias del Perú para facilitar tus transacciones.
                        Entre los bancos disponibles se encuentran:
                        <ul className="text-sm font-medium space-y-2">
                            <li>- Banco de Crédito del Perú (BCP)</li>
                            <li>- Interbank</li>
                        </ul>
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">Límites Bancarios: </span>Para garantizar la
                        seguridad y el cumplimiento de las normativas vigentes, aplicamos los
                        siguientes límites bancarios:
                        <ul className="text-sm font-medium space-y-2">
                            <li>
                                - Depósitos por aplicativo de banco: hasta S/ 50,000 por
                                transacción.
                            </li>
                            <li>
                                - Retiros por aplicativo de banco: hasta S/ 50,000 por transacción.
                            </li>
                            <li>- Pagos en efectivo: hasta S/ 700 por transacción.</li>
                            <li>- Retiros en efectivo: hasta S/ 700 por transacción.</li>
                        </ul>
                        Para montos mayores, contáctanos directamente para brindarte asistencia
                        personalizada.
                    </p>
                    <h4
                        id="help-us"
                        className="text-xl text-center lg:text-2xl text-primary font-bold"
                    >
                        Preguntas frecuentes
                    </h4>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Qué es Treko y qué servicios ofrecen?</span>
                        <br />
                        Respuesta: Treko es una casa de cambios de criptomonedas peruana que permite
                        a los usuarios comprar y vender diversas criptomonedas de manera segura y
                        eficiente. Ofrecemos servicios de intercambio, billeteras digitales, y
                        asesoramiento en inversiones en criptomonedas.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Cómo puedo registrarme en Treko?</span>
                        <br />
                        Respuesta: Para registrarte en Treko, visita nuestra página web y
                        encontrarás un enlace directo a WhatsApp. También puedes escribirnos
                        directamente al +51 972 308 372. A través de este canal, podrás realizar tu
                        registro siguiendo las instrucciones de nuestro operador. Además, si lo
                        prefieres, puedes solicitar asistencia por videollamada a través de
                        WhatsApp.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">
                            ¿Qué criptomonedas puedo comprar y vender en Treko?
                        </span>
                        <br />
                        Respuesta: En Treko ofrecemos una amplia variedad de criptomonedas
                        populares, incluyendo Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), Ripple
                        (XRP), y muchas más. Consulta nuestra plataforma para ver la lista completa
                        de criptomonedas disponibles, son más de 500 criptos.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">
                            ¿Cuáles son las tarifas por transacción en Treko?
                        </span>
                        <br />
                        Respuesta: Las tarifas por transacción en Treko varía en función al tipo de
                        operación, si deseas comprar criptomonedas cuesta 1%, si deseas vender tus
                        criptomonedas cuesta 1.5%, el tipo de cambio de cada criptomoneda está
                        sujeta al valor cotizado en el mercado peruano.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">
                            ¿Cómo puedo comprar y vender fondos en Treko?
                        </span>
                        <br />
                        Respuesta: Para depositar fondos, puedes utilizar transferencias bancarias,
                        hacer depósitos a través de agentes o cajeros automáticos. Aún no aceptamos
                        tarjetas de crédito o débito, pero estamos trabajando para habilitar esta
                        función. También puedes depositar criptomonedas directamente desde otra
                        billetera. Para retirar tus fondos, podemos enviarlos a tu billetera de
                        criptomonedas o a la cuenta bancaria que nos indiques. El proceso no dura
                        más de 5 minutos.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Es seguro utilizar Treko?</span>
                        <br />
                        Respuesta: En Treko, tomamos la seguridad muy en serio. Utilizamos
                        tecnología de encriptación avanzada, autenticación de dos factores y otros
                        protocolos de seguridad para proteger tus fondos y tu información personal.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Ofrecen soporte al cliente?</span>
                        <br />
                        Respuesta: Sí, ofrecemos soporte al cliente a través de varios canales,
                        incluyendo correo electrónico, chat en vivo, redes sociales, whatsapp y
                        videollamadas. Nuestro equipo de soporte está disponible para ayudarte con
                        cualquier consulta o problema que puedas tener.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">
                            ¿Puedo utilizar Treko desde cualquier parte del mundo?
                        </span>
                        <br />
                        Respuesta: Aunque Treko es una casa de cambios peruana, nuestros servicios
                        están disponibles en todo el mundo. Puede enviarnos sus criptomonedas desde
                        cualquier lugar y nosotros realizaremos el pago en su cuenta bancaria
                        peruana. También puede pagarnos a través de su aplicación de banco peruano y
                        nosotros enviaremos las criptomonedas a cualquier billetera, sin importar su
                        ubicación.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Tienen alguna aplicación móvil?</span>
                        <br />
                        Respuesta: Por el momento no, la atención es desde whatsapp pero puede
                        revisar el tipo de cambio desde nuestra página web. Pero te asesoramos para
                        que puedas administrar de manera autónoma tus criptomonedas.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">
                            ¿Ofrecen algún tipo de educación o recursos sobre criptomonedas?
                        </span>
                        <br />
                        Respuesta: Sí, en Treko, creemos firmemente en la educación financiera. Por
                        eso, ofrecemos una sección de recursos en nuestra página web y en nuestras
                        redes sociales, donde puedes encontrar artículos, guías y videos educativos
                        sobre criptomonedas y cómo invertir de manera segura.
                    </p>
                </div>
            </Container>
            {/* <Container className="wow fadeInUp relative -mt-[110px]">
                <div
                    href="#about-us"
                    className="wow fadeInUp text-primary space-y-8 mt-28 mb-16 px-9 py-9"
                >
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        Legal
                    </h4>

                    <p className="text-sm text-center text-primary-light sm:text-base font-medium">
                        LEGALGLALGELGLE
                    </p>
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        LEGALGLALGELGLE
                    </h4>
                    <p className="text-sm text-center sm:text-base text-primary-light font-medium">
                        LEGALGLALGELGLE
                    </p>
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        LEGALGLALGELGLE
                    </h4>

                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        LEGALGLALGELGLE
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        LEGALGLALGELGLE
                    </p>
                </div>
            </Container> */}
            <Container className="wow fadeInUp relative -mt-[110px]">
                <div
                    id="about-us"
                    className="wow fadeInUp text-primary space-y-8 mt-28 mb-16 px-9 py-9"
                >
                    <h1 className="text-3xl text-center sm:text-4xl md:text-5xl lg:text-6xl font-bold">
                        El Mundo Al Alcance
                        <br className="hidden lg:block" /> De Tu Mano
                    </h1>
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        Misión
                    </h4>

                    <p className="text-sm text-center text-primary-light sm:text-base font-medium">
                        En Treko, nos dedicamos a ofrecer servicios de cambio de criptomonedas de
                        manera segura, rápida y confiable. Nuestro compromiso es proporcionar una
                        experiencia excepcional a nuestros clientes.
                    </p>
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        Visión
                    </h4>
                    <p className="text-sm text-center sm:text-base text-primary-light font-medium">
                        Liderar el mercado de criptomonedas en Perú con transparencia, accesibilidad
                        y un fuerte compromiso con la educación financiera. Queremos crear una
                        comunidad informada y empoderada, permitiendo que cada peruano participe en
                        la economía digital de manera segura y equitativa. Nos esforzamos por
                        transformar el mercado financiero peruano, eliminando barreras y promoviendo
                        la inclusión financiera a través de la innovación y el conocimiento.
                    </p>
                    <h4 className="text-xl text-center lg:text-2xl text-primary font-bold">
                        Nosotros
                    </h4>

                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        Somos una empresa especializada en la compra y venta de criptomonedas,
                        ofreciendo soluciones seguras y eficientes para tus necesidades financieras
                        digitales. Al igual que una casa de cambios tradicional convierte dólares a
                        soles, nosotros facilitamos la conversión y gestión de criptomonedas con la
                        misma confianza y profesionalismo.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        Nuestro compromiso es proporcionarte una experiencia transparente y
                        confiable en cada transacción. Con un equipo dedicado y conocimientos
                        sólidos del mercado de criptomonedas, estamos aquí para ayudarte a maximizar
                        tus oportunidades en este emocionante mundo digital. Explora nuestras
                        opciones de servicios y descubre cómo podemos hacer que tus operaciones con
                        criptomonedas sean más accesibles y rentables.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Qué necesita el mercado hoy en día?</span>
                        <br />
                        Enfocamos nuestros esfuerzos en ofrecer precios accesibles para facilitar el
                        acceso a las criptomonedas.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿En qué destacamos?</span>
                        <br />
                        Nos destacamos por nuestra habilidad para ofrecer precios competitivos y
                        realizar transacciones rápidas.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Qué nos apasiona?</span>
                        <br />
                        Nos apasiona educar sobre criptomonedas, ayudando a nuestros clientes a
                        entender y aprovechar este mercado emergente.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Qué productos o servicios ofrecemos?</span>
                        <br />
                        Ofrecemos intercambio de más de 500 criptomonedas por dinero fiat y
                        viceversa, asegurando un servicio seguro y confiable.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Cuáles son nuestros objetivos?</span>
                        <br />
                        Nuestro objetivo es facilitar el acceso justo y transparente a las
                        criptomonedas en el Perú, además de empoderar a nuestros clientes con
                        educación financiera para tomar decisiones informadas y seguras. Buscamos
                        contribuir a una economía digital equitativa y promover la inclusión
                        financiera en el país.
                    </p>
                    <p className="text-sm text-primary-light sm:text-base font-medium">
                        <span className="text-primary">¿Cuál es nuestra visión de futuro?</span>
                        <br />
                        Estamos planeando ofrecer servicios de custodia de criptomonedas para ayudar
                        a nuestros clientes a proteger sus activos digitales y fomentar la confianza
                        en este mercado en crecimiento.
                    </p>
                </div>
            </Container>
            <Container className="wow fadeInUp flex items-center flex-col-reverse xl:flex-row xl:space-y-0 xl:space-x-5">
                <div className="w-full xl:w-1/3">
                    <img
                        src="/images/valores.svg"
                        className="h-auto object-contain object-center align-middle mt-16 xl:mt-0"
                        alt=""
                    />
                </div>

                <div className="w-full xl:w-2/3 grid grid-cols-1 md:grid-cols-3 gap-6 text-primary">
                    <div className="transition-all duration-300 border border-primary-light rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/transparencia.svg" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-primary">
                            Transparencia
                        </h3>
                        <p className="max-w-lg">
                            Operamos con total claridad en nuestras tarifas y procesos para generar
                            confianza y seguridad en nuestros clientes.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-primary-light rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/accesibilidad.svg" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-primary">
                            Accesibilidad
                        </h3>
                        <p className="max-w-lg">
                            Ofrecemos servicios a precios justos para hacer que el mundo de las
                            criptomonedas esté al alcance de todos.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-primary-light rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/educacion.svg" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-primary">Educación</h3>
                        <p className="max-w-lg">
                            Proporcionamos recursos educativos y asesoramiento personalizado para
                            capacitar a nuestros clientes en el uso y manejo de criptomonedas.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-primary-light rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/innovacion.svg" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-primary">Innovación</h3>
                        <p className="max-w-lg">
                            Nos mantenemos a la vanguardia de las tecnologías financieras para
                            ofrecer soluciones eficientes y seguras.
                        </p>
                    </div>

                    <div className="transition-all duration-300 border border-primary-light rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 hover:border-gray-200 hover:border-opacity-50 hover:shadow-2xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/compromisosocial.svg" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-primary">
                            Compromiso Social
                        </h3>
                        <p className="max-w-lg">
                            Trabajamos para reducir la brecha digital y fomentar la inclusión
                            financiera en todas las comunidades del Perú.
                        </p>
                    </div>
                </div>
            </Container>

            {/* <div
                className="mt-28 pt-10 md:pt-28 pb-52 space-y-8 text-center text-white bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: "url('/images/our-solutions-bg.png')" }}
            >
                <h3 className="wow fadeInUp text-xl md:text-2xl text-green-400 font-bold">
                    High speeds. Low fees. No hassle.
                </h3>
                <h1 className="wow fadeInUp text-3xl md:text-4xl xl:text-6xl font-bold">
                    All Your Payments In <br className="hidden lg:block" /> One Place
                </h1>
                <p className="wow fadeInUp text-white font-medium text-opacity-80 text-sm md:text-base">
                    Get used to low fees and great exchange rates on international money <br />{" "}
                    transfers.Expand your business worldwide
                </p>
            </div> */}
            {/* 
            <Container className="-mt-[150px] wow fadeInUp">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-1.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">Payments</h3>
                        <p className="max-w-lg text-lg text-indigo-900">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>

                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-2.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                            Collections
                        </h3>
                        <p className="max-w-lg text-lg text-indigo-900">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>

                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-3.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                            Conversions
                        </h3>
                        <p className="max-w-lg text-lg text-indigo-900">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>

                    <div className="w-2/3 mx-auto md:w-full bg-white transition-all duration-300 border border-gray-200 border-opacity-50 rounded-3xl p-4 lg:p-8 text-center flex flex-col space-y-8 shadow-xl">
                        <div className="flex items-center justify-center">
                            <img src="/images/our-solutions-icon-4.png" alt="" />
                        </div>
                        <h3 className="text-xl lg:text-2xl font-bold text-indigo-900">
                            Global Account
                        </h3>
                        <p className="max-w-lg text-lg text-indigo-900">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                        </p>
                    </div>
                </div>

                <div className="flex items-center flex-col-reverse  lg:flex-row space-x-16 mt-24">
                    <img
                        src="/images/app-download-img.png"
                        className="w-full lg:w-1/2 mt-8 lg:mt-0 object-cover object-center"
                        alt=""
                    />

                    <div className="w-full lg:w-1/2 space-y-8">
                        <div className="text-indigo-900 space-y-8 mt-28">
                            <h4 className="text-xl text-indigo-600 lg:text-2xl font-bold">
                                App Download
                            </h4>

                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
                                Fast, Secure Money <br className="hidden lg:block" /> Transfers
                            </h1>

                            <p className="text-sm sm:text-base">
                                Access your account via your mobile phone. View balance, transfer{" "}
                                <br /> funds, view transactions wherever you are.
                            </p>
                        </div>

                        <div className="space-y-5 text-indigo-900">
                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>Login with fingerprint or Face ID.</p>
                            </div>

                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>Simple few Taps to send money.</p>
                            </div>

                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>View transaction history.</p>
                            </div>

                            <div className="flex items-center space-x-3 lg:space-x-5">
                                <img src="/images/check.png" alt="" />
                                <p>Get instant App notifications.</p>
                            </div>
                        </div>

                        <div className="flex items-center space-x-3">
                            <button className="transition-all duration-300 hover:shadow-xl">
                                <img src="/images/GooglePlay-btn.png" alt="" />
                            </button>

                            <button className="transition-all duration-300 hover:shadow-xl">
                                <img src="/images/apple-btn.png" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </Container> */}

            <Footer />
        </div>
    );
};

export default Home;
