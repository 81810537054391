import { Container } from "../components/utils";

const Footer = () => {
    return (
        <footer className="bg-[#FBFBFF] py-24 mt-24 border-t border-indigo-100">
            <Container>
                <img
                    src="/images/bg_treko.svg"
                    className="w-full object-center object-cover border-b"
                    alt=""
                />

                <div className="mt-8 flex items-center justify-between">
                    <p className="text-gray-500 uppercase text-sm md:text-base">
                        Copyright © 2024 Treko. All Rights Reserved.
                    </p>
                    <p className="text-gray-500 text-s md:text-base">
                        <a
                            href="https://forms.gle/XFmRqAX2fB4tFVjN8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex"
                        >
                            <img src="/images/book.svg" className="w-6 h-6" />
                            Libro de Reclamaciones
                        </a>
                    </p>

                    <div className="flex items-center space-x-4 text-indigo-800">
                        <a
                            href="https://www.instagram.com/treko_crypto/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2"
                        >
                            <img src="/images/ig.svg" className="w-6 h-6" />
                        </a>

                        <a
                            href="https://www.facebook.com/profile.php?id=61561376600213"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2"
                        >
                            <img src="/images/fb.svg" className="w-6 h-6" />
                        </a>

                        <a
                            href="https://www.tiktok.com/@treko_crypto"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2"
                            style={{ width: "45px" }}
                        >
                            <img src="/images/tiktok.svg" className="w-6 h-6" />
                        </a>

                        <a
                            href="https://youtube.com/@treko-crypto?si=D7wa2_aqjQKqc8RG"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-white shadow-lg border border-gray-300 border-opacity-30 rounded-full p-2"
                        >
                            <img src="/images/yt.svg" className="w-6 h-6" />
                        </a>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
